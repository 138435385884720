import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Container from '../components/Container';
import Text from '../components/Text';

import Perks from '../components/Perks';
import Testimonials from '../components/Testimonials';
import config from '../config';

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const HeroImageContent = styled.div`
  width: 100%;
  height: 500px;
  position: relative;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > h1 {
      margin-bottom: 0.7rem;
    }
  }
`;

const AdditionalContent = styled.div`
  width: 100%;
  min-height: 350px;
  height: auto;
  margin: ${config.sizes.spacing * 2}px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;

  > h2 {
    margin-bottom: ${config.sizes.spacing}px;
  }
`;

const ExtraContent = ({ title, children }) => (
  <AdditionalContent>
    <Text as="h2" size="large" weight="bold">
      {title}
    </Text>
    {children}
  </AdditionalContent>
);

const Index = ({ data }) => (
  <Content>
    <HeroImageContent>
      <Img fluid={data.file.childImageSharp.fluid} style={{ height: '100%' }} />
      <div>
        <Text as="h1" size="x-large" weight="bold" color="white">
          Therapeutic Kneads
        </Text>
        <Text as="p" size="medium" color="white" italic align="center">
          Discover your perfect balance with personalized massage therapy
        </Text>
      </div>
    </HeroImageContent>
    <Container>
      <ExtraContent title="Reasons Why You'll Love Us">
        <Perks />
      </ExtraContent>
      <ExtraContent title="Meet The Team">
        <Testimonials />
      </ExtraContent>
    </Container>
  </Content>
);

export const query = graphql`
  query {
    file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(quality: 100, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Index;
