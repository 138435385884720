import React from 'react';
import styled from 'styled-components';

import Media from '../../helpers/mediaTemplates';

import Testimonial from './Testimonial';
import { useStaticQuery, graphql } from 'gatsby';

const getTestimonialForImageName = imageName => {
  switch (imageName) {
    case '1-cindy':
      return {
        name: 'Cindy Ryan',
        instagram: '',
        description:
          'Since graduating from Sutherland Chan in Toronto in 2007, Cindy’s dedication to helping others live pain-free has been the heart of her practice. With a focus on chronic pain relief, she brings a wealth of experience and passion to each treatment, leading to the successful opening of Therapeutic Kneads in 2016.',
      };
    case '2-jme':
      return {
        name: 'Jessica Mary-Ellen',
        instagram: '',
        description:
          'Graduating from Centennial College in 2016, Jessica Mary-Ellen’s strength and warmth make her an integral part of our team. Known for her powerful yet personalized deep tissue techniques, Jessica creates a light-as-a-feather experience for her clients, helping them leave each session with lasting relief.',
      };
    case '3-yao':
      return {
        name: 'Yao',
        instagram: '',
        description:
          'Since earning her credentials at Trillium College in 2009, Yao has become a sought-after therapist at Therapeutic Kneads, with clients often booking months in advance. Her specialty lies in deep relaxation techniques that melt away muscle tension, delivering treatments that leave clients feeling completely renewed.',
      };
    default:
      return {};
  }
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;

  > div:not(:first-child):not(:last-child) {
    margin: 0 1rem;
  }

  ${Media.MOBILE`
    flex-direction: column;

    > div:not(:first-child):not(:last-child) {
      margin: 1rem 0;
    }
  `}
`;

const Testimonials = () => {
  // Where we will store the perks
  const [testimonials, setTestimonials] = React.useState([]);

  // get all the park images
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: {
            extension: { regex: "/(jpg)/" }
            relativeDirectory: { eq: "testimonials" }
          }
        ) {
          edges {
            node {
              name
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(height: 100, width: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `
  );

  // If the images change (which they shouldnt), set the testimonials and images
  React.useEffect(() => {
    const newTestimonials = [];

    allFile.edges.forEach(({ node }) => {
      // name is the image filename (i.e 1-miranda)
      const { childImageSharp, name } = node;

      newTestimonials.push({
        ...getTestimonialForImageName(name),
        image: childImageSharp.fixed,
      });
    });

    setTestimonials(newTestimonials);
  }, [allFile]);

  return (
    <Container>
      {testimonials.map(testimonial => (
        <Testimonial key={testimonial.name} {...testimonial} />
      ))}
    </Container>
  );
};

export default Testimonials;
